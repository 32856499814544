// IMPORTS
import { lazy } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// PARTS
const InventoryTable = lazy(
    () => import('../parts/inventoryTable/InventoryTable')
);
const ViewPart = lazy(() => import('../parts/viewPart/ViewPart'));
const ImportPriceFile = lazy(
    () => import('../parts/importSupplierFile/ImportPriceFile')
);

// SALE
const SaleTable = lazy(() => import('../parts/saleTable/SaleTable'));
const NewSale = lazy(() => import('../parts/newSale/NewSale'));
const CreditSale = lazy(() => import('../parts/creditSale/CreditSale'));

// STOCK MANAGEMENT
const StockAdjustmentTable = lazy(
    () => import('../stock/stockAdjustment/StockAdjustmentTable')
);
const StockOrderTable = lazy(
    () => import('../stock/stockOrderTable/StockOrderTable')
);
const StockOrderUI = lazy(() => import('../stock/stockOrder/StockOrderUI'));
const NewStockInvoice = lazy(
    () => import('../stock/stockInvoice/NewStockInvoice')
);
const StockTransferTable = lazy(
    () => import('../stock/stockTransferTable/StockTransferTable')
);
const BinLocationsTable = lazy(
    () => import('../stock/binLocations/BinLocationsTable')
);
const StocktakeTable = lazy(() => import('../stock/stocktake/StocktakeTable'));
const Stocktake = lazy(() => import('../stock/stocktake/Stocktake'));
const CreditInvoiceUI = lazy(
    () => import('../stock/creditStockInvoice/CreditInvoiceUI')
);

// MISC
const VoucherTable = lazy(() => import('../parts/voucher/VoucherTable'));
const ViewInvoice = lazy(() => import('../stock/viewInvoice/ViewInvoice'));

// NOT IN TS
const BackorderTable = lazy(
    () => import('../parts/backorderTable/BackorderTable')
);

// DASHBOARD
const PartsDashboard = lazy(() => import('../parts/dashboard/PartsDashboard'));

const PartsRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                {/* DASHBOARD */}
                <Route path={`dashboard`} element={<PartsDashboard />} />

                {/* PARTS */}
                <Route
                    path={`search`}
                    element={<InventoryTable key={new Date().getTime()} />}
                />
                <Route path={`view/:partId`} element={<ViewPart />} />

                {/* SALE */}
                <Route path={`sales`} element={<SaleTable />} />
                <Route
                    path={`newSale`}
                    key={new Date().getTime()}
                    element={<NewSale />}
                />
                <Route path={`viewSale/:id`} element={<NewSale />} />
                <Route path={`credit/:id`} element={<CreditSale />} />
                <Route path={`viewCredit/:id`} element={<CreditSale />} />
                {/* STOCK MANAGEMENT */}
                <Route
                    path={`stockAdjustments`}
                    element={<StockAdjustmentTable />}
                />
                <Route path={`binLocations`} element={<BinLocationsTable />} />
                <Route path={`stockorders`} element={<StockOrderTable />} />
                <Route
                    path={`stockorder`}
                    key={new Date().getTime()}
                    element={<StockOrderUI />}
                />
                <Route
                    path={`stockInvoiceNoPO/`}
                    element={<NewStockInvoice />}
                />
                <Route
                    path={`stockInvoicePO/:id`}
                    element={<NewStockInvoice />}
                />
                <Route
                    path={`stocktransfers`}
                    element={<StockTransferTable />}
                />
                <Route path={`stocktake`} element={<StocktakeTable />} />
                <Route path={`newstocktake/:id`} element={<Stocktake />} />
                <Route
                    path={`creditInvoice/view/:id`}
                    element={
                        <RoleCheck
                            component={<CreditInvoiceUI />}
                            permission={'access_creditStockInvoice'}
                            pageError
                        />
                    }
                />
                <Route
                    path={`creditInvoice`}
                    element={
                        <RoleCheck
                            component={<CreditInvoiceUI />}
                            permission={'access_creditStockInvoice'}
                            pageError
                        />
                    }
                />

                {/* MISC */}
                <Route path={`vouchers`} element={<VoucherTable />} />
                <Route path={`viewInvoice/:id`} element={<ViewInvoice />} />
                <Route path={`backorders`} element={<BackorderTable />} />

                {/* NOT IN TS */}
                <Route
                    path={`importSupplierFiles`}
                    element={
                        <RoleCheck
                            component={<ImportPriceFile />}
                            permission={'import_supplierFile'}
                            pageError
                        />
                    }
                />
            </Route>
        </Routes>
    );
};

export default PartsRouter;
