import { Chip, Typography } from '@mui/material';

interface DealerDocumentStatusHeaderProps {
    documentType: string;
    documentId: string;
    documentStatus?: string | null;
}

const DealerDocumentStatusHeader = ({
    documentType,
    documentId,
    documentStatus
}: DealerDocumentStatusHeaderProps) => {
    const statusMap = {
        vendorSigned: { label: 'Vendor Signed', color: 'success' },
        customerSigned: { label: 'Customer Signed', color: 'success' },
        waitingSignature: { label: 'Waiting for Signature', color: 'warning' },
        waitingDeliverySignature: {
            label: 'Waiting for Delivery Receipt Signature',
            color: 'warning'
        },
        deliverySigned: { label: 'Delivery Receipt Signed', color: 'success' },
        default: { label: 'Unsigned', color: 'default' }
    };

    const { label, color } = statusMap[documentStatus] || statusMap.default;

    return (
        <Typography variant="h4">
            {`${documentType} #${documentId} `}{' '}
            {documentStatus && (
                <Chip label={label} variant="outlined" color={color} />
            )}
        </Typography>
    );
};

export default DealerDocumentStatusHeader;
