import { Button } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import DrawSignature from './DrawSignature';
import { RequiredField } from '../EsignInterface';

interface AddSignatureDialogProps {
    open: boolean;
    onClose: () => void;
    customerName: string;
    setSignatureDataUrl: Dispatch<SetStateAction<string>>;
    fieldName: string;
    setRequiredFields?: Dispatch<SetStateAction<RequiredField[]>>;
    step?: number;
}

const AddSignatureDialog = ({
    open,
    onClose,
    customerName,
    setSignatureDataUrl,
    fieldName,
    setRequiredFields,
    step
}: AddSignatureDialogProps) => {
    const [tempSignatureDataUrl, setTempSignatureDataUrl] =
        useState<string>('');

    // // two tabs allow customer to select signature style or draw signature
    // const AddSignatureTabs = [
    //     {
    //         id: 0,
    //         title: 'Draw Signature',
    //         content: (
    //             <DrawSignature
    //                 setTempSignatureDataUrl={setTempSignatureDataUrl}
    //             />
    //         )
    //     },
    //     {
    //         id: 1,
    //         title: 'Select Signature',
    //         content: (
    //             <SelectSignature
    //                 name={customerName}
    //                 setTempSignatureDataUrl={setTempSignatureDataUrl}
    //             />
    //         )
    //     }
    // ];

    const handleAddSignature = () => {
        localStorage.setItem(fieldName, tempSignatureDataUrl);
        setSignatureDataUrl(tempSignatureDataUrl);

        // set corresponding step to signed
        if (setRequiredFields) {
            setRequiredFields((currentFields) => {
                const updatedFields = [...currentFields];
                updatedFields[step - 1].signed = true;
                return updatedFields;
            });
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{'Draw Your Signature'}</DialogTitle>
            <DialogContent>
                {/* <Tabs tabContent={AddSignatureTabs} /> */}
                <DrawSignature
                    setTempSignatureDataUrl={setTempSignatureDataUrl}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    autoFocus
                    onClick={handleAddSignature}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSignatureDialog;
