import api from '../../../../api';

const GetInitialAppSetupData = async ({ SiteId }) => {
    try {
        const response = await api.get('initialAppSetupData', {
            params: {
                SiteId
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export default GetInitialAppSetupData;
