import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#a88ce2',
            main: '#9370DB',
            dark: '#8464c5',
            contrastText: '#fff'
        },
        background: {
            default: '#f5f5f5',
            paper: '#fff'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiSpeedDial: {
            defaultProps: {
                sx: { position: 'fixed', bottom: 16, right: 16 }
            }
        },
        MuiAvatar: {
            defaultProps: {
                sx: { backgroundColor: '#444343' }
            }
        }
    }
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#5f3ba8',
            contrastText: '#fff'
        },
        error: {
            main: '#AE331A'
        },
        warning: {
            main: '#D86B05'
        },
        background: {
            default: '#1f1d1d',
            paper: '#212020'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-shrink': {
                        '&.Mui-disabled': {
                            color: '#fff',
                            opacity: '0.45'
                        },
                        color: '#9370DB'
                    }
                }
            }
        },
        MuiSpeedDial: {
            defaultProps: {
                sx: { position: 'fixed', bottom: 16, right: 16 }
            }
        },
        MuiIconButton: {
            defaultProps: {
                sx: { color: '#9370DB' }
            }
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    color: '#ffffff',
                    borderColor: '#a88ce2'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#a88ce2'
                    }
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                sx: {
                    color: '#ffffff'
                }
            }
        }
    }
});

// Slightly change Ora's colors when in the Support section
if (window.location.href.includes('/support')) {
    darkTheme.palette.background = {
        default: '#34495E',
        paper: '#283747'
    };
    theme.palette.background = {
        default: '#AEB6BF',
        paper: '#85929E'
    };
    theme.components.MuiAvatar = {
        defaultProps: {
            sx: {
                backgroundColor: '#85929E',
                color: 'black'
            }
        }
    };
}
