import { Suspense } from 'react';
import LoadingBackdrop from '../OraNextGen/global/LoadingBackdrop';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //
const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingBackdrop />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;