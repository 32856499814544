// IMPORTS
import { List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import RoleCheck from '../../global/RoleCheck';
import { SubMenuProps } from '../../global/interfaces/GlobalInterface';

const StockSubMenu = ({ isSupportPage }: SubMenuProps) => {
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/stockorders'
                            : '/inventory/stockorders'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Stock Orders
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/stockorder'
                            : '/inventory/stockorder'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        New Stock Order
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/stockInvoiceNoPO'
                            : '/inventory/stockInvoiceNoPO'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Enter Stock Invoice
                    </ListItemText>
                </ListItemButton>

                {/* CREDIT INVOICE */}
                <RoleCheck
                    permission={'access_creditStockInvoice'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/inventory/creditInvoice'
                                    : '/inventory/creditInvoice'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Credit Stock Invoice
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/stocktransfers'
                            : '/inventory/stocktransfers'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Stock Transfers
                    </ListItemText>
                </ListItemButton>

                {!isSupportPage && (
                    <ListItemButton
                        component={Link}
                        to={
                            isSupportPage
                                ? '/support/inventory/stocktake'
                                : '/inventory/stocktake'
                        }
                        sx={{ fontSize: '13px' }}
                    >
                        <ListItemText
                            inset
                            primaryTypographyProps={{ variant: 'caption' }}
                        >
                            Stocktake
                        </ListItemText>
                    </ListItemButton>
                )}

                {!isSupportPage && (
                    <ListItemButton
                        component={Link}
                        to={'/inventory/binLocations'}
                        sx={{ fontSize: '13px' }}
                    >
                        <ListItemText
                            inset
                            primaryTypographyProps={{ variant: 'caption' }}
                        >
                            Bin Locations
                        </ListItemText>
                    </ListItemButton>
                )}
                {!isSupportPage && (
                    <ListItemButton
                        component={Link}
                        to={
                            isSupportPage
                                ? '/support/inventory/stockAdjustments'
                                : '/inventory/stockAdjustments'
                        }
                        sx={{ fontSize: '13px' }}
                    >
                        <ListItemText
                            inset
                            primaryTypographyProps={{ variant: 'caption' }}
                        >
                            Stock Adjustments Table
                        </ListItemText>
                    </ListItemButton>
                )}
            </List>
        </>
    );
};

export default StockSubMenu;
