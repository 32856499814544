// IMPORTS
import { lazy } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import RoleCheck from '../global/RoleCheck';

// DASHBOARD
const ServiceDashboard = lazy(
    () => import('../service/dashboard/ServiceDashboard')
);

// BOOKINGS
const BookingTable = lazy(() => import('../service/bookingTable/BookingTable'));
const NewBooking = lazy(() => import('../service/newBooking/NewBooking'));
const ServicePayment = lazy(
    () => import('../service/servicePayment/ServicePayment')
);

// CODES
const ServiceCodeTable = lazy(
    () => import('../service/serviceCodes/ServiceCodeTable')
);

const ViewServiceCode = lazy(
    () => import('../service/serviceCodes/ViewServiceCode')
);

// MISC
const PurchaseOrderTable = lazy(
    () => import('../service/purchaseOrders/PurchaseOrderTable')
);

// CALENDAR
const ServiceCalendarRBC = lazy(
    () => import('../service/newCalendar/ServiceCalendarRBC')
);
const ServiceCalendarRBCDarkMode = lazy(
    () => import('../service/newCalendar/ServiceCalendarRBCDarkMode')
);

const ServiceRouter = () => {
    const currentTheme = useTheme();
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                {/* BOOKINGS */}
                <Route path={`bookings`} element={<BookingTable />} />
                <Route
                    path={`newBooking`}
                    key={new Date().getTime()}
                    element={<NewBooking />}
                />
                <Route path={`viewBooking/:id`} element={<NewBooking />} />
                <Route path={`payment/:id`} element={<ServicePayment />} />
                <Route path={`payment`} element={<ServicePayment />} />

                {/* CODES */}
                <Route
                    path={`codes/add`}
                    element={
                        <RoleCheck
                            component={<ViewServiceCode />}
                            permission={'access_jobCode'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`codes/view/:id`}
                    element={
                        <RoleCheck
                            component={<ViewServiceCode />}
                            permission={'access_jobCode'}
                            pageError
                        />
                    }
                />
                <Route
                    path={`codes`}
                    element={
                        <RoleCheck
                            component={<ServiceCodeTable />}
                            permission={'access_jobCode'}
                            pageError
                        />
                    }
                />

                {/* MISC */}
                <Route
                    path={`purchaseOrders`}
                    element={<PurchaseOrderTable />}
                />

                {/* BOOKING CALENDAR */}
                <Route
                    path={`calendar`}
                    element={
                        currentTheme.palette.mode === 'dark' ? (
                            <ServiceCalendarRBCDarkMode />
                        ) : (
                            <ServiceCalendarRBC />
                        )
                    }
                />

                {/* Service DASHBOARD */}
                <Route path={`dashboard`} element={<ServiceDashboard />} />
            </Route>
        </Routes>
    );
};

export default ServiceRouter;
