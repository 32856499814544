import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import RoleCheck from '../global/RoleCheck';

// CUSTOMER
const CustomerTable = lazy(() => import('../general/customer/CustomerTable'));
const ViewCustomer = lazy(() => import('../general/customer/ViewCustomer'));

// TEXT AND EMAIL
const TemplateTable = lazy(() => import('../general/template/TemplateTable'));
const Template = lazy(() => import('../general/template/ViewTemplate'));

// CREDITOR
const CreditorTable = lazy(() => import('../general/creditor/CreditorTable'));
const ViewCreditor = lazy(() => import('../general/creditor/ViewCreditor'));
const AddCreditor = lazy(() => import('../general/creditor/AddCreditor'));

// USER
const UserSettings = lazy(() => import('../general/userSettings/UserSettings'));
const UserTable = lazy(() => import('../general/user/UserTable'));
const ViewUser = lazy(() => import('../general/user/ViewUser'));

// SITE
const SiteTable = lazy(() => import('../general/site/SiteTable'));
const ViewSite = lazy(() => import('../general/site/ViewSite'));

// IT
const ApiUITS = lazy(() => import('../general/api/ApiUITS'));
const IPWhitelistUI = lazy(
    () => import('../general/ipWhitelist/IPWhitelistUI')
);
const ViewCompany = lazy(() => import('../general/company/ViewCompany'));
const SpecificsUI = lazy(() => import('../general/specifics/SpecificsUI'));
const TillReconciliation = lazy(
    () => import('../general/tillReconciliation/TillReconciliation')
);

// DAILY TASKS
const DailyTaskPage = lazy(() => import('../general/dailyTasks/DailyTaskPage'));
const HomePage = lazy(() => import('../general/homePage/HomePage'));
const ResetPassword = lazy(() => import('../general/password/ResetPassword'));

const GeneralRouter = ({ currentUser, setCurrentUser }) => {
    return (
        <Routes>
            {/* HOME PAGE*/}
            <Route path="/" element={<HomePage />} />
            {/* CUSTOMTER */}
            <Route path="/customers" element={<CustomerTable />} />
            <Route path={`/viewCustomer/:id`} element={<ViewCustomer />} />
            <Route path={`/addCustomer`} element={<ViewCustomer />} />

            {/* TILL RECONCILIATION */}
            <Route
                path="/tillReconciliation"
                element={<TillReconciliation />}
            />

            {/* TEXT AND EMAIL */}
            <Route path="/templates" element={<TemplateTable />} />
            <Route path="/addTemplate" element={<Template />} />
            <Route path="/viewTemplate/:id" element={<Template />} />

            <Route path="/resetPassword" element={<ResetPassword />} />

            {/* DAILYTASKS */}
            <Route path="/dailytask" element={<DailyTaskPage />} />

            <Route path={`/configureUsers`} element={<UserTable />} />
            <Route path={`/viewUser/:id`} element={<ViewUser />} />

            {/* USER */}
            <Route
                path="/userSettings"
                element={
                    <UserSettings
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                    />
                }
            />

            {/* SPECIFICS MANAGEMENT*/}
            <Route path="/specifics" element={<SpecificsUI />} />

            {/* CREDITOR */}
            <Route
                path="/creditors"
                element={
                    <RoleCheck
                        component={<CreditorTable />}
                        permission={'access_creditors'}
                        pageError
                    />
                }
            />

            <Route
                path={`/viewCreditor/:id`}
                element={
                    <RoleCheck
                        component={<ViewCreditor />}
                        permission={'access_creditors'}
                        pageError
                    />
                }
            />

            <Route
                path={`/addCreditor`}
                element={
                    <RoleCheck
                        component={<AddCreditor />}
                        permission={'add_creditor'}
                        pageError
                    />
                }
            />

            {/* IT */}
            <Route
                path="/apiSettings"
                element={
                    <RoleCheck
                        component={<ApiUITS />}
                        permission={'configure_api'}
                        pageError
                    />
                }
            />

            <Route
                path="/ipWhitelist"
                element={
                    <RoleCheck
                        component={<IPWhitelistUI />}
                        permission={'access_ipWhitelist'}
                        pageError
                    />
                }
            />

            <Route
                path={`/addUser`}
                element={
                    <RoleCheck
                        component={<ViewUser />}
                        permission={'configure_users, configure_personal_users'}
                        pageError
                    />
                }
            />

            {/* SITE */}
            <Route
                path="/sites"
                element={
                    <RoleCheck
                        component={<SiteTable />}
                        permission={'configure_sites'}
                        pageError
                    />
                }
            />
            <Route
                path="/viewSite/:id"
                element={
                    <RoleCheck
                        component={<ViewSite />}
                        permission={'configure_sites'}
                        pageError
                    />
                }
            />

            <Route
                path="/addSite"
                element={
                    <RoleCheck
                        component={<ViewSite />}
                        permission={'configure_sites'}
                        pageError
                    />
                }
            />

            {/* COMPANY  SETTINGS*/}
            <Route
                path="/companySettings"
                element={
                    <RoleCheck
                        component={<ViewCompany />}
                        permission={'configure_company'}
                        pageError
                    />
                }
            />
        </Routes>
    );
};

export default GeneralRouter;
