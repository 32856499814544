import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import PageWrapper from '../../global/PageWrapper';

// IMPORT COMPONENTS
import FileLoadingPage from '../../esign/components/FileLoadingPage';
import CustomerSubmitSuccessPage from '../../esign/components/CustomerSubmitSuccessPage';

// IMPORT PDF LIBRARY
import AddSignatureDialog from '../../esign/components/AddSignatureDialog';
import jsPDF from 'jspdf';
// import * as PDFjs from 'pdfjs-dist';
import CustomerAppBar from '../../esign/components/CustomerAppBar';
import SignatureField from '../../esign/components/SignatureField';
import LoadDocumentOnCanvas from '../../esign/logic/LoadDocumentOnCanvas';
import dayjs from 'dayjs';
import { UploadFileToS3 } from '../../esign/logic/S3FileService';
import { LoadImage, RenderDate } from '../../esign/logic/CanvasUtils';

import * as PDFjs from 'pdfjs-dist/legacy/build/pdf.mjs';
import { EmailSignedPdf } from '../signContract/logic/EmailContract';

PDFjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.6.82/legacy/build/pdf.worker.min.mjs`;

const CustomerSignPersonalisedPlateForm = ({
    customerInfo,
    personalisedPlateStatus,
    requiredFields,
    setRequiredFields,
    dateFields,
    showSnackbar
}) => {
    const { id: vehicleSaleId } = useParams<{ id: string }>();
    const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [step, setStep] = useState(0);
    const [signDialogOpen, setSignDialogOpen] = useState(false);

    const canvasRefs = useRef([]);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const loadPersonalisedPlatePdf = async () => {
        const numPages = await LoadDocumentOnCanvas({
            folderName: 'vehicleSalePersonalisedPlate',
            fileName: parseInt(vehicleSaleId),
            canvasRefs,
            setPdfLoaded
        });

        if (numPages > 0) {
            setNumberOfPages(numPages);
        } else {
            showSnackbar(
                'Failed to display document',
                ' Please try again later.',
                'error'
            );
        }
    };

    useEffect(() => {
        loadPersonalisedPlatePdf();
        // eslint-disable-next-line
    }, [vehicleSaleId, pdfLoaded]);

    const today = dayjs().format('DD/MM/YYYY');
    const [firstSigDataUrl, setFirstSigDataUrl] = useState<string>(null);
    const [secondSigDataUrl, setSecondSigDataUrl] = useState<string>(null);
    const [operatorSigDataUrl, setOperatorSigDataUrl] = useState<string>(null);

    const onClickSignatureField = (fieldId: number, fieldName: string) => {
        const sigDataUrl = localStorage.getItem(fieldName);

        if (sigDataUrl) {
            fieldId === 0
                ? setFirstSigDataUrl(sigDataUrl)
                : fieldId === 1
                  ? setSecondSigDataUrl(sigDataUrl)
                  : setOperatorSigDataUrl(sigDataUrl);

            setRequiredFields((currentFields) =>
                currentFields.map((field) =>
                    field.id === fieldId ? { ...field, signed: true } : field
                )
            );
        } else {
            setSignDialogOpen(true);
            setStep(fieldId + 1);
        }
    };

    const onClearSignature = (fieldId, fieldName) => {
        localStorage.removeItem(fieldName);

        fieldId === 0
            ? setFirstSigDataUrl(null)
            : fieldId === 1
              ? setSecondSigDataUrl(null)
              : setOperatorSigDataUrl(null);

        setRequiredFields((currentFields) =>
            currentFields.map((field) =>
                field.id === fieldId ? { ...field, signed: false } : field
            )
        );
    };

    const onCloseDialog = () => {
        setSignDialogOpen(false);
    };

    const handleClickSubmit = async () => {
        setLoading(true);

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4'
        });

        const pdfWidth = pdf.internal.pageSize.width;
        const pdfHeight = pdf.internal.pageSize.height;

        const scalingRatio =
            window.innerWidth >= 2000
                ? 1.5
                : window.innerWidth >= 1200
                  ? 2
                  : window.innerWidth >= 600
                    ? 3
                    : 4;

        // Ensure image is loaded and drawn before generating PDF
        try {
            if (personalisedPlateStatus === 'waitingSignature') {
                // draw first signature on canvas
                await LoadImage({
                    canvas: canvasRefs.current[1],
                    sigDataUrl: localStorage.getItem('firstSig'),
                    left: 0.47,
                    top: 0.19,
                    scalingRatio
                });

                // only render the second signature when second signature is signed
                if (secondSigDataUrl) {
                    await LoadImage({
                        canvas: canvasRefs.current[1],
                        sigDataUrl: localStorage.getItem('secondSig'),
                        left: 0.47,
                        top: 0.24,
                        scalingRatio
                    });
                }

                await LoadImage({
                    canvas: canvasRefs.current[1],
                    sigDataUrl: localStorage.getItem('operatorSig'),
                    left: 0.09,
                    top: 0.7,
                    scalingRatio
                });

                RenderDate({
                    canvas: canvasRefs.current[1],
                    date: today,
                    left: 0.81,
                    top: 0.18,
                    scalingRatio
                });

                // only render the second date when second signature is signed
                if (secondSigDataUrl) {
                    RenderDate({
                        canvas: canvasRefs.current[1],
                        date: today,
                        left: 0.81,
                        top: 0.22,
                        scalingRatio
                    });
                }

                RenderDate({
                    canvas: canvasRefs.current[1],
                    date: today,
                    left: 0.43,
                    top: 0.68,
                    scalingRatio
                });
            }
        } catch (error) {
            showSnackbar(
                'Failed to load or draw image',
                'Please try again later.',
                'error'
            );
            setLoading(false);
            return; // Stop execution if image fails to load
        }

        // add each canvas as a page to the pdf
        canvasRefs.current.forEach((canvas, index) => {
            if (canvas) {
                const imgData = canvas.toDataURL('image/jpeg');
                if (index === 0) {
                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                } else {
                    pdf.addPage();
                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                }
            }
        });

        // upload loa to S3
        const base64data = pdf.output('datauristring');

        // Upload the updated contract to S3
        UploadFileToS3({
            base64data: base64data,
            folderName: 'vehicleSalePersonalisedPlate',
            fileName: parseInt(vehicleSaleId),
            showSnackbar: showSnackbar
        })
            .then(() => {
                EmailSignedPdf({
                    type: 'personalised-plate-form',
                    id: Number(vehicleSaleId),
                    emailAttachments: [
                        {
                            fileName: `Personalised_Plate_Form_#${vehicleSaleId}`,
                            base64data: base64data
                        }
                    ],
                    newStatus: 'customerSigned',
                    setIsSubmitSuccess: setIsSubmitSuccess
                });
            })
            .catch((error) => {
                showSnackbar(
                    'File upload failed',
                    'Please try again later.',
                    'error'
                );
                throw error;
            })
            .finally(() => setLoading(false));
    };

    if (!pdfLoaded) {
        return <FileLoadingPage />;
    }

    if (isSubmitSuccess) {
        return <CustomerSubmitSuccessPage />;
    }

    return (
        <PageWrapper notVisible={true}>
            <Grid container spacing={2}>
                <CustomerAppBar
                    requiredFields={requiredFields}
                    handleClickSubmit={handleClickSubmit}
                    loading={loading}
                    step={step}
                    setStep={setStep}
                />

                {Array.from({ length: numberOfPages }, (_, i) => (
                    <Grid
                        item
                        xs={12}
                        lg={8}
                        style={{ margin: 'auto' }}
                        key={i}
                    >
                        <Box style={{ position: 'relative' }}>
                            <canvas
                                ref={(el) => (canvasRefs.current[i] = el)}
                                style={{
                                    width: '100%',
                                    height: 'auto'
                                }}
                            />
                            {/* render all signature fields on specific page and position*/}
                            {requiredFields.map(
                                (field) =>
                                    i === field.pageNo && (
                                        <SignatureField
                                            key={field.id}
                                            ref={field.ref}
                                            onClick={() =>
                                                onClickSignatureField(
                                                    field.id,
                                                    field.name
                                                )
                                            }
                                            onClearSignature={() =>
                                                onClearSignature(
                                                    field.id,
                                                    field.name
                                                )
                                            }
                                            isSigned={field.signed}
                                            signatureDataUrl={
                                                field.id === 0
                                                    ? firstSigDataUrl
                                                    : field.id === 1
                                                      ? secondSigDataUrl
                                                      : operatorSigDataUrl
                                            }
                                            top={field.top}
                                            left={field.left}
                                        />
                                    )
                            )}
                            {/* render all date fields on specific page and position*/}
                            {dateFields.map(
                                (field) =>
                                    i === field.pageNo && (
                                        <Typography
                                            ref={field.ref}
                                            style={{
                                                position: 'absolute',
                                                top: field.top,
                                                left: field.left,
                                                fontSize: field.fontSize
                                            }}
                                        >
                                            {today}
                                        </Typography>
                                    )
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <AddSignatureDialog
                open={signDialogOpen}
                onClose={onCloseDialog}
                customerName={customerInfo?.name}
                setSignatureDataUrl={
                    step === 1
                        ? setFirstSigDataUrl
                        : step === 2
                          ? setSecondSigDataUrl
                          : setOperatorSigDataUrl
                }
                fieldName={
                    step === 1
                        ? 'firstSig'
                        : step === 2
                          ? 'secondSig'
                          : 'operatorSig'
                }
                setRequiredFields={setRequiredFields}
                step={step}
            />
        </PageWrapper>
    );
};

export default CustomerSignPersonalisedPlateForm;
