import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

// this is dealer send contract link to customer
interface EmailSignUrlProps {
    type: string;
    id: number;
    customerSignUrl: string;
    newStatus: string;
    showSnackbar: showSnackbar;
    hasSecondOperator?: boolean;
}

export const EmailSignUrl = ({
    type,
    id,
    customerSignUrl,
    newStatus,
    showSnackbar,
    hasSecondOperator
}: EmailSignUrlProps) => {
    api.post('/emailSignUrl', {
        type: type,
        id: id,
        url: customerSignUrl,
        newStatus: newStatus,
        hasSecondOperator: hasSecondOperator
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Email sent successfully',
                'The page will now refresh.',
                'success'
            );

            let redirectUrl;

            if (
                type === 'vehicle-contract' ||
                type === 'loa' ||
                type === 'personalisedPlate'
            ) {
                redirectUrl = `/vehicles/viewContract/${id}`;
            } else {
                redirectUrl = `/vehicles/view/${id}`;
            }

            setTimeout(() => (window.location.href = redirectUrl), 1000);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

// this is customer send back signed contract pdf in attachment to dealer
interface EmailSignedPdfProps {
    type: string;
    id: number;
    emailAttachments: {
        fileName: string;
        base64data: string;
    }[];
    newStatus: string;
    setIsSubmitSuccess?: (value: boolean) => void;
    showSnackbar?: showSnackbar;
}

export const EmailSignedPdf = ({
    type,
    id,
    emailAttachments,
    newStatus,
    setIsSubmitSuccess,
    showSnackbar
}: EmailSignedPdfProps) => {
    return api
        .post('/emailSignedPdf', {
            type: type,
            id: id,
            newStatus: newStatus,
            emailAttachments: emailAttachments
        })
        .then((res) => {
            if (res.status === 200) {
                setIsSubmitSuccess?.(true);
                showSnackbar?.(
                    'Email sent successfully',
                    'Please refresh the page.',
                    'success'
                );
            } else {
                setIsSubmitSuccess?.(false);
                showSnackbar?.(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
};
