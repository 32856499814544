// IMPORTS
import { lazy } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// MISC
const OnlineOrders = lazy(() => import('../online/OnlineOrders'));
const OnlinePaymentManagement = lazy(
    () => import('../online/OnlinePaymentManagement')
);

const OnlineRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                <Route
                    path="orders"
                    element={
                        <RoleCheck
                            component={<OnlineOrders />}
                            permission={'access_online'}
                            pageError
                        />
                    }
                />
                <Route
                    path="paymentManagement"
                    element={
                        <RoleCheck
                            component={<OnlinePaymentManagement />}
                            permission={'admin_manager'}
                            pageError
                        />
                    }
                />
            </Route>
        </Routes>
    );
};

export default OnlineRouter;
