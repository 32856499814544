import { useState } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../../../api';
import { useNavigate, useParams } from 'react-router-dom';

const schema = yup
    .object({
        accessCode: yup.string().required('Access code is required')
    })
    .required();

interface VerifySignerProps {
    setToken: (token: string) => void;
}

interface FormData {
    accessCode: string;
}

const VerifySigner = ({ setToken }: VerifySignerProps) => {
    const { documentId } = useParams<{ documentId: string }>();
    const [backendError, setBackendError] = useState<string>('');
    const navigate = useNavigate();

    // Redirect to the page where the customer was before logging in by reading the redirect query parameter
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl = searchParams.get('redirect');

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched'
    });

    const onSubmit = async (data: FormData) => {
        try {
            const res = await api.post('/verifyAccessCode', {
                accessCode: data.accessCode,
                documentId
            });

            const { error, verified, token } = res.data;

            if (verified) {
                setToken(token);
                api.defaults.headers.common['Authorization'] =
                    'Bearer ' + localStorage.getItem('token');

                if (redirectUrl) {
                    navigate(decodeURIComponent(redirectUrl));
                }
            }

            if (error) {
                setBackendError(error);
            }
        } catch (error) {
            setBackendError(error.message);
        }
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ height: '100vh' }}
        >
            <Grid item xs={10} sm={6}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" textAlign={'left'}>
                        Please find the access code in the email you received
                        (Case Sensitive).
                    </Typography>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <TextField
                        {...register('accessCode')}
                        error={!!errors.accessCode}
                        helperText={errors.accessCode?.message}
                        required
                        fullWidth
                        label="Access Code"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    {backendError && (
                        <Typography color="error">{backendError}</Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Proceed
                    </Button>
                </form>
            </Grid>
        </Grid>
    );
};

export default VerifySigner;
