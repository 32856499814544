import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

// SUPPORT ROUTES
const OverviewSupportPage = lazy(() => import('../documentation/overview'));
const AccountSupportPage = lazy(
    () => import('../documentation/admin/account/account')
);
const ReportingSupportPage = lazy(
    () => import('../documentation/admin/reporting/reporting')
);
const EnterInvoiceSupportPage = lazy(
    () => import('../documentation/admin/enterInvoice')
);
const CreditorsSupportPage = lazy(
    () => import('../documentation/admin/creditors')
);
const CreditInvoiceSupportPage = lazy(
    () => import('../documentation/admin/creditInvoice')
);
const GeneralJournalSupportPage = lazy(
    () => import('../documentation/admin/generalJournal')
);
const ManualTransactionSupportPage = lazy(
    () => import('../documentation/admin/manualTransaction')
);
const BankReconciliationSupportPage = lazy(
    () => import('../documentation/admin/reconciliators/bankReconciliation')
);
const DebtorReconciliationSupportPage = lazy(
    () => import('../documentation/admin/reconciliators/debtorReconciliation')
);
const CreditorReconciliationSupportPage = lazy(
    () => import('../documentation/admin/reconciliators/creditorReconciliation')
);
const RebateReconciliatorSupportPage = lazy(
    () => import('../documentation/admin/reconciliators/rebateReconciliator')
);
const RegoReconciliatorSupportPage = lazy(
    () => import('../documentation/admin/reconciliators/regoReconciliator')
);
const TimeClockSupportPage = lazy(
    () => import('../documentation/admin/timeClock')
);
const CustomersSupportPage = lazy(
    () => import('../documentation/customers/customers')
);
const InventorySupportPage = lazy(
    () => import('../documentation/inventory/inventory')
);
const NewSaleSupportPage = lazy(() => import('../documentation/parts/newSale'));
const SalesSupportPage = lazy(() => import('../documentation/parts/sales'));
const VouchersSupportPage = lazy(
    () => import('../documentation/parts/vouchers')
);
const BackorderSupportPage = lazy(
    () => import('../documentation/parts/backorders')
);
const NewVehicleSaleSupportPage = lazy(
    () => import('../documentation/vehicleSales/newVehicleSale')
);
const NewVehicleTradeSupportPage = lazy(
    () => import('../documentation/vehicleSales/newVehicleTrade')
);
const VehicleSalesSupportPage = lazy(
    () => import('../documentation/vehicleSales/vehicleSales')
);
const InvoiceVehiclesSupportPage = lazy(
    () => import('../documentation/vehicleSales/invoiceVehicles')
);
const StockVehiclesSupportPage = lazy(
    () => import('../documentation/vehicleSales/stockVehicles')
);
const VehicleTransfersSupportPage = lazy(
    () => import('../documentation/vehicleSales/transfers')
);
const LeadsManagementSupportPage = lazy(
    () => import('../documentation/vehicleSales/leadsManagement')
);
const NewStockOrderSupportPage = lazy(
    () => import('../documentation/stockControl/newStockOrder')
);
const EnterStockInvoiceSupportPage = lazy(
    () => import('../documentation/stockControl/enterStockInvoice')
);
const CreditStockInvoiceSupportPage = lazy(
    () => import('../documentation/stockControl/creditStockInvoice')
);
const StockOrderSupportPage = lazy(
    () => import('../documentation/stockControl/stockOrder')
);
const StockTransfersSupportPage = lazy(
    () => import('../documentation/stockControl/stockTransfers')
);
const CalendarSupportPage = lazy(
    () => import('../documentation/service/calendar')
);
const BookingsSupportPage = lazy(
    () => import('../documentation/service/bookings')
);
const PurchaseOrderSupportPage = lazy(
    () => import('../documentation/service/purchaseOrder')
);
const NewBookingSupportPage = lazy(
    () => import('../documentation/service/newBooking')
);
const ServicePaymentSupportPage = lazy(
    () => import('../documentation/service/payment')
);
const TillReconciliationSupportPage = lazy(
    () => import('../documentation/tillReconciliation/tillReconciliation')
);

const DocumentationRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                {/* SUPPORT ROUTERS */}
                {/* Support admin accounts page */}
                <Route path="admin/accounts" element={<AccountSupportPage />} />

                {/* Support admin reporting page */}
                <Route
                    path="admin/reporting"
                    element={<ReportingSupportPage />}
                />

                {/* Support admin creditors page */}
                <Route
                    path="admin/creditors"
                    element={<CreditorsSupportPage />}
                />

                {/* Support admin enter invoice page */}
                <Route
                    path="admin/enterInvoice"
                    element={<EnterInvoiceSupportPage />}
                />

                <Route
                    path="admin/creditInvoice"
                    element={<CreditInvoiceSupportPage />}
                />

                <Route
                    path="admin/generalAccountingEntry"
                    element={<GeneralJournalSupportPage />}
                />

                <Route
                    path="admin/manualTransaction"
                    element={<ManualTransactionSupportPage />}
                />

                <Route
                    path="admin/bankReconciliation"
                    element={<BankReconciliationSupportPage />}
                />

                <Route
                    path="admin/creditorReconciliation"
                    element={<CreditorReconciliationSupportPage />}
                />

                <Route
                    path="admin/debtorReconciliation"
                    element={<DebtorReconciliationSupportPage />}
                />

                <Route
                    path="admin/rebateReconciliation"
                    element={<RebateReconciliatorSupportPage />}
                />

                <Route
                    path="admin/registrationReconciliation"
                    element={<RegoReconciliatorSupportPage />}
                />

                <Route
                    path="admin/timeclock"
                    element={<TimeClockSupportPage />}
                />

                {/* Support Customers page */}
                <Route path="customers" element={<CustomersSupportPage />} />

                {/* Support Inventory search page */}
                <Route
                    path="inventory/search"
                    element={<InventorySupportPage />}
                />

                {/*  Part & Accessories Support page */}
                <Route path="inventory/sales" element={<SalesSupportPage />} />

                <Route
                    path="inventory/newSale"
                    element={<NewSaleSupportPage />}
                />

                <Route
                    path="inventory/vouchers"
                    element={<VouchersSupportPage />}
                />

                <Route
                    path="inventory/backorders"
                    element={<BackorderSupportPage />}
                />

                {/*  Stock Control Support page */}
                <Route
                    path="inventory/stockorders"
                    element={<StockOrderSupportPage />}
                />
                <Route
                    path="inventory/stockorder"
                    element={<NewStockOrderSupportPage />}
                />

                <Route
                    path="inventory/stockInvoicePO"
                    element={<EnterStockInvoiceSupportPage />}
                />

                <Route
                    path="inventory/stockInvoiceNoPO"
                    element={<EnterStockInvoiceSupportPage />}
                />

                <Route
                    path="inventory/creditInvoice"
                    element={<CreditStockInvoiceSupportPage />}
                />

                <Route
                    path="inventory/stocktransfers"
                    element={<StockTransfersSupportPage />}
                />

                {/*  Vehicle Sales Support page */}
                <Route
                    path="vehicles/invoiceVehicles"
                    element={<InvoiceVehiclesSupportPage />}
                />

                <Route
                    path="vehicles/stock"
                    element={<StockVehiclesSupportPage />}
                />
                <Route
                    path="vehicles/sales"
                    element={<VehicleSalesSupportPage />}
                />

                <Route
                    path="vehicles/newContract"
                    element={<NewVehicleSaleSupportPage />}
                />

                <Route
                    path="vehicles/newTrade"
                    element={<NewVehicleTradeSupportPage />}
                />

                <Route
                    path="vehicles/vehicletransfers"
                    element={<VehicleTransfersSupportPage />}
                />

                <Route
                    path="vehicles/leadsmanagement"
                    element={<LeadsManagementSupportPage />}
                />

                {/*  Service Support page */}
                <Route
                    path="service/calendar"
                    element={<CalendarSupportPage />}
                />

                <Route
                    path="service/bookings"
                    element={<BookingsSupportPage />}
                />

                <Route
                    path="service/newBooking"
                    element={<NewBookingSupportPage />}
                />

                <Route
                    path="service/purchaseOrders"
                    element={<PurchaseOrderSupportPage />}
                />

                <Route
                    path="service/payment"
                    element={<ServicePaymentSupportPage />}
                />

                {/*  Till Reconciliation Support page */}
                <Route
                    path="tillReconciliation"
                    element={<TillReconciliationSupportPage />}
                />

                {/* Support Home page */}
                <Route path="" element={<OverviewSupportPage />} />
            </Route>
        </Routes>
    );
};

export default DocumentationRouter;
