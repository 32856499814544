import { Backdrop, CircularProgress } from '@mui/material';
import { theme } from '../../main/theme';

const LoadingBackdrop = ({ open = true, loadingTheme = theme }) => {
    const zIndex = loadingTheme.zIndex.drawer + 1;
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: zIndex
            }}
            open={open}
            onClick={() => null}
        >
            <CircularProgress color="inherit" size={60} />
        </Backdrop>
    );
};
export default LoadingBackdrop;
