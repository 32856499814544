import api from '../../../../api';

interface GetDocumentInfoProps {
    documentType: string;
    vehicleSaleId: string;
    showSnackbar?: any;
}

const GetDocumentInfo = async ({
    documentType,
    vehicleSaleId,
    showSnackbar
}: GetDocumentInfoProps) => {
    let endpoint = '';

    switch (documentType) {
        case 'personalised-plate-form':
            endpoint = '/getPersonalisedPlateFormInfo';
            break;
        case 'contract':
            endpoint = '/getContractInfo';
            break;
        case 'loa':
            endpoint = '/getLOAInfo';
            break;
        default:
            return null;
    }

    const res = await api.get(`${endpoint}/${vehicleSaleId}`);

    if (res.status === 200) {
        return res.data;
    } else {
        showSnackbar?.(
            'Failed to load data',
            'Please try again later',
            'error'
        );
        return null;
    }
};

export default GetDocumentInfo;
