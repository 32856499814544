import dayjs from 'dayjs';

// form 7 default values
export const DEFAULT_FORM7_FORM_VALUES = {
    hasWrittenOff: 'no',
    hasDamage: 'no',
    hasRepaired: 'no',
    hasChecked: 'no',
    hasDealerGuarantee: 'yes',
    isImported: 'no',
};


// dealer personalise plate form default values
export const DEFAULT_PERSONALISED_PLATE_FORM_VALUES = {
    personalisedPlate: '',
    reasonOption: 'remove',
    registeredOption: 'yes',
    cancelOption: 'no',
    noticeOption: 'no',
    differentOwnerOption: 'no',
    authorityOption: 'remove',
    secondPlateOwnerName: '',
    emailOption: 'no'
};

// dealer vendor statement form default values
export const DEFAULT_VENDOR_STATEMENT_FORM_VALUES = {
    // Vendor details
    acn: '',

    // Vehicle Details
    builtDate: null,
    personalPlateOption: 'NO',
    newRego: '',

    // Statement
    accessories: '',

    // statement section 1
    engineReplaceOption: 'NO',
    engineReplaceDate: null,
    odometerReplaceOption: 'NO',
    concessionalRegistrationOption: 'NO',
    airBagsActivatedOption: 'NO',
    modificationsApprovalOption: 'NO',
    currentWrittenOffRegisterOption: 'NO',
    previousWrittenOffRegisterOption: 'NO',

    // statement section 2
    withSecurityInterest: 'NO',
    lenderName: '',
    loanAmount: '',
    payoutByDealer: 'NO',

    // statement section 4
    taxiOrHireCarUsage: 'NO',

    // statement section 6
    auctionOption: 'NO',
    reservePrice: '',

    // statement section 8
    gstRegistrationOption: 'NO',
    taxableSupplyOption: 'NO',
    gstFreeSupplyOption: 'NO'
};

// dealer loa form default values
export const DEFAULT_LOA_FORM_VALUES = {
    // 1. Vehicle details
    payTerm: '',
    commonDueDate: null,

    // 2. Registered operator/s details
    driverLicenseExpiryDate: null,

    // Second Registered Operator (if applicable)
    hasSecondOperator: false,
    roleTypeOption: '',
    operatorOption: '',
    crn: '',
    driverLicenseExpiry2: '',
    name: '',
    registrationNumber: '',

    vehicleGarageAddress: 'as above',
    vehicleGaragePostcode: '',

    //  4. Personalised/customised plates
    attachPlateOption: 'no',
    plateNumber: '',

    // 5. Plate Type to be used for vehicle registration?
    plateTypeOption: '',

    // 6. Purpose of use
    purposeOption: 'private',

    // 7. Concession details
    concessionOption: 'no',

    // 8. Duty
    dutyExemptOption: 'no',
    exemptionDesc: '',

    // 9. Compulsory Third Party (CTP) insurance
    insurerOption: 'Suncorp',
    ITCEOption: 'no'
};

// dealer loa organisation form default values
export const DEFAULT_LOA_ORGANISATION_FORM_VALUES = {
    // 1. Vehicle details
    commonDueDate: null,

    // 2. Registered operator/s details
    operatorOption: '',
    otherTypeValue: '',
    organisationACN: '',

    // Second Registered Operator (if applicable)
    hasSecondOperator: false,
    roleTypeOption: '',
    secondOperatorOption: '',
    otherTypeValue2: '',
    crn: '',
    driverLicenseExpiry2: '',
    name: '',
    registrationNumber: '',

    vehicleGarageAddress: 'as above',
    vehicleGaragePostcode: '',

    //  4. Personalised/customised plates
    attachPlateOption: 'no',
    plateNumber: '',

    // 5. Plate Type to be used for vehicle registration?
    plateTypeOption: '',

    // 6. Purpose of use
    purposeOption: 'private',

    // 7. Concession details
    concessionOption: 'no',

    // 8. Duty
    dutyExemptOption: 'no',
    exemptionDesc: '',

    // 9. Compulsory Third Party (CTP) insurance
    insurerOption: 'Suncorp',
    ITCEOption: 'no'
};

export const USE_PURPOSE_OPTIONS = [
    { value: 'private', label: 'Private' },
    { value: 'dealer', label: 'Dealer' },
    { value: 'taxi', label: 'Taxi' },
    { value: 'limousine', label: 'Limousine' },
    { value: 'farming', label: 'Farming' },
    {
        value: 'schoolActivities',
        label: 'School activities only (transporting children, teachers)'
    },
    { value: 'driverTraining', label: 'Driver training or tuition' },
    { value: 'nonProfit', label: 'Non profit' },
    { value: 'publicPassenger', label: 'Public passenger service' },
    { value: 'bookedHire', label: 'Booked hire' },
    { value: 'towTruck', label: 'Licensed tow truck' },
    { value: 'transLink', label: 'TransLink urban service' },
    { value: 'commercial', label: 'Commercial' },
    { value: 'rental', label: 'Rental(self driver)' },
    { value: 'bookedHireRental', label: 'Booked hire rental' },
    { value: 'specialPurposeLimousine', label: 'Special Purpose Limousine' },
    { value: 'emergency', label: 'Emergency' }
];

const coolingOffPeriodStart = dayjs();

// default 1 business day after coolingOffPeriodStart; business day is Monday to Saturday
let coolingOffPeriodEnd = coolingOffPeriodStart.add(1, 'day');
// if coolingOffPeriodEnd is Sunday, add 1 more day
if (coolingOffPeriodEnd.day() === 0) {
    coolingOffPeriodEnd = coolingOffPeriodEnd.add(1, 'day');
}

export const DEFAULT_VEHICLE_SALE_FORM_VALUES = {
    // PurchaserCreditForm
    creditOption: 'noCreditNeeded',
    contact: '',
    phone: '',
    premium: '',

    // DELIVERY RECEIPT
    deliveryDate: null,
    purchaserSigDataURL: '',
    witnessSigDataURL: '',

    // WithholdingDeclarationForm
    mainTaxInvoiceOption: 'provideTaxInvoice',
    subTaxInvoiceOption: '',
    abnForGst: '',
    abnNotForGST: '',
    declarationWitnessSigDataURL: '',
    dealerAcceptDate: null,

    // WrittenOffForm
    writtenOffOption: '',

    // CoolingOffForm
    coolingOffOption: 'hasCoolingOffPeriod',
    // default now
    coolingOffPeriodStart: coolingOffPeriodStart,
    coolingOffPeriodEnd: coolingOffPeriodEnd,

    // StatutoryWarrantyForm
    statutoryWarrantyOption: 'noWarranty',
    warrantorName: '',
    warrantorAddress: '',
    warrantorSuburb: '',
    warrantorState: '',
    warrantorPostcode: '',
    warrantorPhone: '',
    warrantorEmail: '',
    buyerPossessionDate: null,

    // DealerDeclarationForm
    noPriorContract: false,
    notPersuadeCoolingOff: false,
    hasStatutoryWarranty: false,
    noStatutoryWarranty: false,
    notPersuadeWarranty: false,
    informedBuyer: false,
    dealerSignDate: null,
    dealerSigDataURL: ''
};
