import { useEffect, useRef, useState } from 'react';
import CustomerSignLOA from './component/CustomerSignLOA';
import { useParams } from 'react-router-dom';
import CustomerSubmitSuccessPage from '../../esign/components/CustomerSubmitSuccessPage';
import GetDocumentInfo from '../../esign/logic/GetDocumentInfo';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { RequiredField } from '../../esign/EsignInterface';
import ClearTokenOnExit from '../../esign/logic/CleanTokenOnExit';

const CustomerLOAPage = ({ showSnackbar }) => {
    let { id: vehicleSaleId } = useParams<{ id: string }>();

    //decode type from url
    const type = new URLSearchParams(window.location.search).get('type');
    const decodedType = atob(decodeURIComponent(type));
    // get screen width
    const screenWidth = window.innerWidth;
    // dynamically set the size of the signature date text font size
    const signDateFontSize =
        screenWidth >= 1200 ? '16px' : screenWidth / 60 + 'px';

    const [customerInfo, setCustomerInfo] = useState(null);
    const [loaStatus, setLoaStatus] = useState(null);

    // list all required fields, each field has an id, ref, sign status, page number, and position
    const firstSigRef = useRef(null);
    const secondSigRef = useRef(null);

    const individualRequiredFields = [
        {
            id: 0,
            name: 'firstSig',
            ref: firstSigRef,
            required: true,
            signed: false,
            pageNo: 1,
            top: '70%',
            left: '8%'
        }
    ];

    const individualDateFields = [
        {
            id: 0,
            name: 'signDate',
            ref: useRef(null),
            pageNo: 1,
            top: '69%',
            left: '81%',
            fontSize: signDateFontSize
        }
    ];

    const organisationRequiredFields = [
        {
            id: 0,
            name: 'firstSig',
            ref: useRef(null),
            required: true,
            signed: false,
            pageNo: 1,
            top: '86%',
            left: '47%'
        }
    ];

    const organisationDateFields = [
        {
            id: 0,
            name: 'signDate',
            ref: useRef(null),
            pageNo: 1,
            top: '85%',
            left: '81%',
            fontSize: signDateFontSize
        }
    ];

    const [requiredFields, setRequiredFields] = useState<RequiredField[]>(
        decodedType === 'organisation'
            ? organisationRequiredFields
            : individualRequiredFields
    );

    const dateFields =
        decodedType === 'organisation'
            ? organisationDateFields
            : individualDateFields;

    const getLOAInfo = async () => {
        const res = await GetDocumentInfo({
            documentType: 'loa',
            vehicleSaleId
        });
        setCustomerInfo(res.customerInfo);
        setLoaStatus(res.loaStatus);

        // if there is a second operator, add a second signature field
        if (res.hasSecondOperator) {
            const secondSigField = {
                id: 1,
                name: 'secondSig',
                ref: secondSigRef,
                required: true,
                signed: false,
                pageNo: 1,
                top: '70%',
                left: '47%'
            };
            setRequiredFields([...requiredFields, secondSigField]);
        }
    };

    useEffect(() => {
        if (vehicleSaleId) {
            getLOAInfo();
        }
        // eslint-disable-next-line
    }, [vehicleSaleId]);

    // Remove auth token from local storage when the user closes the tab or refreshes the page, user will have to sign again
    useEffect(() => {
        ClearTokenOnExit();
    }, []);

    return (
        <>
            {loaStatus === 'waitingSignature' ? (
                <CustomerSignLOA
                    customerInfo={customerInfo}
                    decodedType={decodedType}
                    requiredFields={requiredFields}
                    setRequiredFields={setRequiredFields}
                    dateFields={dateFields}
                    showSnackbar={showSnackbar}
                />
            ) : loaStatus === 'customerSigned' ? (
                <CustomerSubmitSuccessPage />
            ) : null}
        </>
    );
};

export default withSnackbar(CustomerLOAPage);
