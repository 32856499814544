// IMPORTS
import { useContext } from 'react';
import { Card, CardHeader, Avatar, IconButton, Tooltip } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { Site, User } from '../../global/interfaces/GeneralInterface';
import { TitleCaseFormatter } from '../../global/logic/Formatters';
import { SiteContext, UserContext } from '../../../main/Main';

const AvatarCard = () => {
    const currentUser = useContext<User | null>(UserContext);
    const currentSite = useContext<Site>(SiteContext);

    const handleLogOut = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    };

    return (
        <>
            <Card elevation={0}>
                <CardHeader
                    title={TitleCaseFormatter(
                        currentUser?.firstName + ' ' + currentUser?.lastName
                    )}
                    titleTypographyProps={{ variant: 'body2' }}
                    subheader={`${currentUser?.username} | ${currentSite?.name}`}
                    subheaderTypographyProps={{ variant: 'caption' }}
                    avatar={
                        <Avatar>
                            {currentUser?.firstName?.charAt(0)?.toUpperCase()}
                        </Avatar>
                    }
                    action={
                        <Tooltip title="Log Out" placement="left">
                            <IconButton onClick={handleLogOut}>
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    }
                />
            </Card>
        </>
    );
};

export default AvatarCard;
