import { useParams } from 'react-router-dom';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { useEffect, useRef, useState } from 'react';
import CustomerSubmitSuccessPage from '../../esign/components/CustomerSubmitSuccessPage';
import CustomerSignPersonalisedPlateForm from './CustomerSignPersonalisedPlateForm';
import GetDocumentInfo from '../../esign/logic/GetDocumentInfo';
import { RequiredField } from '../../esign/EsignInterface';
import ClearTokenOnExit from '../../esign/logic/CleanTokenOnExit';

const CustomerPersonalisedPlateFormPage = ({ showSnackbar }) => {
    let { id: vehicleSaleId } = useParams<{ id: string }>();
    // get screen width
    const screenWidth = window.innerWidth;
    // dynamically set the size of the signature date text font size
    const signDateFontSize =
        screenWidth >= 1200 ? '16px' : screenWidth / 60 + 'px';

    const RequiredFields = [
        {
            id: 0,
            name: 'firstSig',
            ref: useRef(null),
            required: true,
            signed: false,
            pageNo: 1,
            top: '19%',
            left: '47%'
        },
        {
            id: 1,
            name: 'secondSig',
            ref: useRef(null),
            required: false,
            signed: false,
            pageNo: 1,
            top: '24%',
            left: '47%'
        },
        {
            id: 2,
            name: 'operatorSig',
            ref: useRef(null),
            required: true,
            signed: false,
            pageNo: 1,
            top: '70%',
            left: '9%'
        }
    ];

    const dateFields = [
        {
            id: 0,
            name: 'signDate',
            ref: useRef(null),
            pageNo: 1,
            top: '18%',
            left: '81%',
            fontSize: signDateFontSize
        },
        {
            id: 1,
            name: 'signDate',
            ref: useRef(null),
            pageNo: 1,
            top: '23%',
            left: '81%',
            fontSize: signDateFontSize
        },
        {
            id: 2,
            name: 'signDate',
            ref: useRef(null),
            pageNo: 1,
            top: '69%',
            left: '43%',
            fontSize: signDateFontSize
        }
    ];

    const [customerInfo, setCustomerInfo] = useState(null);
    const [personalisedPlateStatus, setpersonalisedPlateStatus] =
        useState(null);
    const [requiredFields, setRequiredFields] =
        useState<RequiredField[]>(RequiredFields);

    const getPersonalisedPlateInfo = async () => {
        const res = await GetDocumentInfo({
            documentType: 'personalised-plate-form',
            vehicleSaleId
        });
        setCustomerInfo(res.customerInfo);
        setpersonalisedPlateStatus(res.personalisedPlateStatus);
    };

    useEffect(() => {
        if (vehicleSaleId) {
            getPersonalisedPlateInfo();
        }
        // eslint-disable-next-line
    }, [vehicleSaleId]);

    // Remove auth token from local storage when the user closes the tab or refreshes the page, user will have to sign again
    useEffect(() => {
        ClearTokenOnExit();
    }, []);

    return (
        <>
            {personalisedPlateStatus === 'waitingSignature' ? (
                <CustomerSignPersonalisedPlateForm
                    customerInfo={customerInfo}
                    personalisedPlateStatus={personalisedPlateStatus}
                    requiredFields={requiredFields}
                    setRequiredFields={setRequiredFields}
                    dateFields={dateFields}
                    showSnackbar={showSnackbar}
                />
            ) : personalisedPlateStatus === 'customerSigned' ? (
                <CustomerSubmitSuccessPage />
            ) : null}
        </>
    );
};

export default withSnackbar(CustomerPersonalisedPlateFormPage);
