// IMPORTS
import { useState, useContext } from 'react';
import { Backdrop } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import MuiSpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
// COMPONENTS
import { PermissionsContext } from '../../main/Main';

interface ActionList {
    icon;
    name: string;
    permission?: string;
    noDisplay?: boolean;
    onClick?: () => void;
    cypressLabel?: string;
}
interface SpeedDialProps {
    actionsList: ActionList[];
    bottom?: number;
    right?: number;
    cypressLabel?: string;
}

const SpeedDial = ({
    actionsList,
    bottom,
    right,
    cypressLabel
}: SpeedDialProps) => {
    const permissions = useContext<string[]>(PermissionsContext);

    const [isDialOpen, setIsDialOpen] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    // Handle the click of one of the speeddial actions
    const handleClick = (clickAction) => {
        setIsDialOpen(false);
        setIsDisabled(true);
        clickAction();
        setTimeout(() => {
            setIsDisabled(false);
        }, 2000);
    };

    return (
        <>
            <MuiSpeedDial
                icon={
                    <SpeedDialIcon
                        icon={<ExpandLess />}
                        openIcon={<ExpandMore />}
                    />
                }
                open={isDialOpen}
                onClose={() => setIsDialOpen(false)}
                onOpen={() => setIsDialOpen(true)}
                ariaLabel="speedDial"
                data-cy={cypressLabel ? cypressLabel : ''}
            >
                {actionsList.map((action: ActionList) => {
                    if (
                        (!action.permission ||
                            (action.permission &&
                                permissions.includes(action.permission))) &&
                        !action.noDisplay
                    ) {
                        return (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                FabProps={{ disabled: isDisabled }}
                                onClick={() => {
                                    if (isDisabled) {
                                        void 0;
                                    } else {
                                        handleClick(action.onClick);
                                    }
                                }}
                                tooltipTitle={action.name}
                                tooltipOpen
                                data-cy={
                                    action.cypressLabel
                                        ? action.cypressLabel
                                        : ''
                                }
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            </MuiSpeedDial>

            <Backdrop
                sx={{ backgroundColor: '#5454547a', zIndex: 999 }}
                open={isDialOpen}
                onClick={() => setIsDialOpen(false)}
                transitionDuration={300}
            ></Backdrop>
        </>
    );
};

export default SpeedDial;
