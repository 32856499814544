import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

// CUSTOMER SIGN VENDOR STATEMENT PAGE
import CustomerTradeDocManager from '../vehicle/signVendorStatement/CustomerTradeDocManager';

// CUSTOMER SIGN LOA PAGE
import CustomerLOAPage from '../vehicle/signLOA/CustomerLOAPage';

// CUSTOMER SIGN CONTRACT PAGE
import CustomerContractPage from '../vehicle/signContract/CustomerContractPage';
import CustomerPersonalisedPlateFormPage from '../vehicle/signPersonalisedPlateForm/CustomerPersonalisedPlateFormPage';
import VerifyCustomer from '../esign/components/VerifyCustomer';
import VerifySigner from '../esign/components/VerifySigner';

const EsignRouter = ({ token, setToken }) => {
    const [customerLoggedIn, setCustomerLoggedIn] = useState(true);

    const ProtectedCustomerRoute = ({
        token,
        customerLoggedIn,
        setCustomerLoggedIn
    }) => {
        const location = useLocation();
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            if (location.pathname.includes('/esign/')) {
                let decoded;
                try {
                    if (token) {
                        decoded = jwtDecode(token);
                    }
                } catch (error) {
                    decoded = false;
                }
                if (
                    decoded &&
                    decoded.exp > Date.now() / 1000 &&
                    decoded?.id != null
                ) {
                    setCustomerLoggedIn(true);
                } else {
                    setCustomerLoggedIn(false);
                    localStorage.setItem('token', null);
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
        }, [location, token, setCustomerLoggedIn]);

        if (isLoading) {
            return null;
        }

        return customerLoggedIn ? (
            <Outlet />
        ) : (
            <Navigate
                to={{
                    pathname: '/esign/login',
                    search: `?redirect=${encodeURIComponent(location.pathname + location.search)}`
                }}
            />
        );
    };

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ProtectedCustomerRoute
                        token={token}
                        customerLoggedIn={customerLoggedIn}
                        setCustomerLoggedIn={setCustomerLoggedIn}
                    />
                }
            >
                <Route
                    path="vendor-statement/:id"
                    element={<CustomerTradeDocManager />}
                />
                <Route path="loa/:id" element={<CustomerLOAPage />} />
                <Route
                    path="vehicle-contract/:id"
                    element={<CustomerContractPage />}
                />
                <Route
                    path="personalised_plate_form/:id"
                    element={<CustomerPersonalisedPlateFormPage />}
                />
            </Route>
            <Route
                path="/login"
                element={<VerifyCustomer setToken={setToken} />}
            />
            <Route
                path="/verify/:documentId"
                element={<VerifySigner setToken={setToken} />}
            />
        </Routes>
    );
};

export default EsignRouter;
