// IMPORTS
import { useEffect, useState, ReactNode, useContext } from 'react';
// LOGIC
import ForbiddenPage from './ForbiddenPage';
import { PermissionsContext } from '../../main/Main';

interface RoleCheckProps {
    permission: string;
    component: ReactNode;
    nullComponent?: ReactNode;
    pageError?: boolean;
}

const RoleCheck = ({
    permission,
    component,
    nullComponent,
    pageError
}: RoleCheckProps) => {
    const [display, setDisplay] = useState(false);
    const permissions = useContext<string[]>(PermissionsContext);

    // Check if the current user has the specified permission
    useEffect(() => {
        let tempDisplay = false;
        let splitPermissions = permission.replaceAll(' ', '').split(',');

        for (let i = 0; i < splitPermissions.length; i++) {
            let item = String(splitPermissions[i]);
            if (permissions.includes(item)) {
                tempDisplay = true;
            }
        }

        setDisplay(tempDisplay);
        // eslint-disable-next-line
    }, [permissions]);

    return display ? (
        <>{component}</>
    ) : nullComponent ? (
        <>{nullComponent}</>
    ) : pageError ? (
        <ForbiddenPage />
    ) : (
        <></>
    );
};

export default RoleCheck;
