import { useForm, Controller } from 'react-hook-form';
import {
    Typography,
    Button,
    Paper,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

interface DealerPersonalisedPlateFormProps {
    type: string;
    formData: any;
    setFormData: any;
    onDealerPersonalisedPlateForm: any;
    customer?: any;
    sendEmailLoading?: boolean;
}

const DealerPersonalisedPlateForm = ({
    type,
    formData,
    setFormData,
    onDealerPersonalisedPlateForm,
    customer,
    sendEmailLoading
}: DealerPersonalisedPlateFormProps) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            ...formData
        }
    });

    const handlePreviewChanges = async (data) => {
        setFormData(data);
        await onDealerPersonalisedPlateForm(data, 'preview');

        if (type === 'vehicleSale') {
            setHasPreviewed(true);
        }
    };

    // below only applies to vehicle sale personalise plate form; trade in form do not have email feature here
    const [hasPreviewed, setHasPreviewed] = useState(false);
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const onOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    };

    const handleClickSendEmail = async (data) => {
        await onDealerPersonalisedPlateForm(data, 'upload');
    };

    return (
        <>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Section A - Personalised/customised number
                                plate/vehicle details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                1. Personalised/customised number plate
                                combination
                            </Typography>
                            <br />
                            <Controller
                                name="personalisedPlate"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Personalised/customised number plate combination"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                2. Reason for this application
                            </Typography>
                            <Controller
                                name="reasonOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="remove"
                                            control={<Radio />}
                                            label="Remove personalised/customised plates from a vehicle"
                                        />
                                        <FormControlLabel
                                            value="attach"
                                            control={<Radio />}
                                            label="Attach personalised/customised plates to a vehicle"
                                        />
                                        <FormControlLabel
                                            value="replace"
                                            control={<Radio />}
                                            label="Remove personalised/customised plates from a vehicle and replace with another set of personalised/customised plates"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                4. Is the vehicle to remain registered?
                            </Typography>
                            <Controller
                                name="registeredOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                5. Are the personalised/customised number plates
                                to be cancelled?
                            </Typography>
                            <Controller
                                name="cancelOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                6. Is the vehicle currently under a Number Plate
                                Confiscation Notice issued by the Queensland
                                Police Service?
                            </Typography>
                            <Controller
                                name="noticeOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                7. Are the owner/s of the
                                personalised/customised number plates different
                                to the registered operator/s of the vehicle?
                            </Typography>
                            <Controller
                                name="differentOwnerOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Section B - Personalised/customised number plate
                                owner/s details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                8. Personalised/customised plate owner/s
                                authority
                            </Typography>
                            <Controller
                                name="authorityOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="attach"
                                            control={<Radio />}
                                            label="I/We authorise for the personalised/customised number plates to be attached"
                                        />
                                        <FormControlLabel
                                            value="remove"
                                            control={<Radio />}
                                            label="I/We authorise for the personalised/customised number plates to be removed"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                component="div"
                            >
                                Second plate owner’s full name (if applicable)
                            </Typography>
                            <br />
                            <Controller
                                name="secondPlateOwnerName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Second plate owner’s full name (if applicable)"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        {/* // buttons to preview and email the form data */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="error">
                                * Always preview the changes before emailing the
                                customer.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(handlePreviewChanges)}
                                variant="contained"
                                sx={{ my: 3 }}
                            >
                                Preview Changes
                            </Button>
                        </Grid>
                        {type === 'vehicleSale' && (
                            <Grid item xs={6} textAlign={'center'}>
                                <Button
                                    onClick={handleSubmit(onOpenEmailDialog)}
                                    variant="contained"
                                    sx={{ my: 3 }}
                                    disabled={!hasPreviewed}
                                >
                                    Email Customer
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Paper>
            <Dialog open={openEmailDialog}>
                <DialogTitle>Email Customer</DialogTitle>
                <DialogContent>
                    An email with a link to sign will be sent to{' '}
                    <b>{customer?.name}</b> at the following email address:{' '}
                    <b>{customer?.email}</b>.
                    <br /> <br /> Always preview the document before sending an
                    email to the customer.{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmailDialog(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={sendEmailLoading}
                        variant="contained"
                        onClick={handleSubmit(handleClickSendEmail)}
                    >
                        Send Email
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DealerPersonalisedPlateForm;
