import api from '../../../../api';

const GetCompanyLogo = (SiteId: number, setCompanyLogo) => {
    api.get(`settingsNextGen/${SiteId}?includeImage=true`).then((res) => {
        // If the response status is 307: there is no site configured. This is a new Ora Instance and it needs to go through onboarding
        if (res.status === 307) {
            window.location.href = '/onboarding';
        } else {
            setCompanyLogo(res.data.image);
        }
    });
};

export default GetCompanyLogo;
