// IMPORTS
import { lazy } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// VEHICLE
const VehicleTable = lazy(() => import('../vehicle/vehicleTable/VehicleTable'));
const Vehicle = lazy(() => import('../vehicle/vehicle/Vehicle'));
const ManufacturerVehicleTable = lazy(
    () => import('../vehicle/manufacturerVehicleTable/ManufacturerVehicleTable')
);
const VehicleTransferTable = lazy(
    () => import('../vehicle/vehicleTransferTable/VehicleTransferTable')
);
const InvoiceVehicle = lazy(
    () => import('../vehicle/invoiceVehicle/InvoiceVehicle')
);
const UpdateVehicleRRPs = lazy(
    () => import('../vehicle/updateVehicleRRP/UpdateVehicleRRPs')
);
const ViewVehicleInvoice = lazy(
    () => import('../vehicle/invoiceVehicle/ViewVehicleInvoice')
);

// SALE
const NewVehicleSale = lazy(
    () => import('../vehicle/newVehicleSale/NewVehicleSale')
);
const VehicleSaleTable = lazy(
    () => import('../vehicle/vehicleSalesTable/VehicleSaleTable')
);

// TRADE
const TradeVehicle = lazy(() => import('../vehicle/tradeVehicle/TradeVehicle'));
// LEADS MANAGEMENT
const LeadsManagement = lazy(
    () => import('../vehicle/leadsManagement/LeadsManagement')
);
const ManagerDashboard = lazy(
    () => import('../vehicle/leadsManagement/components/ManagerDashboard')
);

// VEHICLE DASHBOARD
const VehicleSaleDashboard = lazy(
    () => import('../vehicle/dashboard/VehicleSaleDashboard')
);

const VehicleRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                <Route path={`dashboard`} element={<VehicleSaleDashboard />} />
                <Route path={`stock`} element={<VehicleTable />} />
                <Route path={`view/:id`} element={<Vehicle />} />
                <Route
                    path={`manufacturerVehicles`}
                    element={<ManufacturerVehicleTable />}
                />
                <Route
                    path={`vehicletransfers`}
                    element={<VehicleTransferTable />}
                />
                <Route path={`invoiceVehicles`} element={<InvoiceVehicle />} />
                <Route
                    path={`viewVehicleInvoice/:id`}
                    element={<ViewVehicleInvoice />}
                />
                <Route path={`updatePrices`} element={<UpdateVehicleRRPs />} />

                {/* SALE */}
                <Route path={`viewContract/:id`} element={<NewVehicleSale />} />
                <Route
                    path={`newContract`}
                    key={new Date().getTime()}
                    element={<NewVehicleSale />}
                />
                <Route path={`sales`} element={<VehicleSaleTable />} />

                {/* TRADE */}
                <Route path={`viewTrade/:id`} element={<TradeVehicle />} />
                <Route path={`newTrade`} element={<TradeVehicle />} />

                {/* NOT IN TS */}
                <Route
                    path={`leadsmanagement/dashboard`}
                    element={
                        <RoleCheck
                            component={<ManagerDashboard />}
                            permission={'access_leadManager'}
                            pageError
                        />
                    }
                />

                <Route
                    path={`leadsmanagement/`}
                    element={<LeadsManagement />}
                />
            </Route>
        </Routes>
    );
};

export default VehicleRouter;
